<template>
  <div>
    <ca-page-header :title="$t('app.get_started')" icon="mdi-flag-variant">
      {{ t("header_subtitle_1") }}<br />
      {{ t("header_subtitle_2") }}
    </ca-page-header>

    <v-row justify="center">
      <v-col lg="8" md="12" sm="12">
        <v-stepper v-if="currentStep" v-model="currentStep" vertical>
          <v-stepper-step :complete="currentStep > 1" step="1">
            {{ t("step1.title") }}
          </v-stepper-step>
          <v-stepper-content step="1">
            <div class="mb-4">
              <span v-html="t('step1.description')"></span>
              <br />
              <i>{{ t("step1.hint") }}</i>
            </div>
            <v-btn color="primary" :to="{ name: 'payouts' }">
              {{ t("add_payment_details") }}
            </v-btn>
            <v-btn text @click="updateStep(2)">
              {{ t("skip_step") }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="currentStep > 2" step="2">
            {{ t("step2.title") }}
          </v-stepper-step>
          <v-stepper-content step="2">
            <div class="mb-4">
              {{ t("step2.description") }}
            </div>
            <v-btn
              :loading="activatingProgram['fd1']"
              :color="activePrograms.fd1 ? 'success' : 'default'"
              class="ml-1"
              @click.native="activateProgram('fd1')"
              ><v-icon class="mr-2" style="font-size: 16px; height: 16px; width: 16px;">$turbobit</v-icon>
              TURBOBIT.NET</v-btn
            >

            <v-btn
              :loading="activatingProgram['fd2']"
              :color="activePrograms.fd2 ? 'success' : 'default'"
              class="ml-4"
              @click.native="activateProgram('fd2')"
              ><v-icon class="mr-2">$hitfile</v-icon> HITFILE.NET</v-btn
            >

            <div class="mt-4">
              <i>{{ t("step2.hint") }}</i>
            </div>

            <v-btn v-if="hasActiveProgram" color="primary" class="mt-2" @click="updateStep(3)">
              {{ t("next_step") }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="currentStep > 3" step="3">
            {{ t("step3.title") }}
          </v-stepper-step>
          <v-stepper-content step="3">
            <div class="mb-4">
              <span v-html='t("step3.description1")'></span>
              <br />
              <span v-html="t('step3.description2')"></span>
              <br />
              <i>{{ t("step3.hint") }}</i>
            </div>
            <v-btn color="primary" @click="updateStep(4)">
              {{ t("done") }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="currentStep > 4" step="4">
            {{ t("step4.title") }}
          </v-stepper-step>
          <v-stepper-content step="4">
            <div class="mb-4">
              {{ t("step4.description1") }}
              <br />
              {{ t("step4.description2") }}
            </div>
            <v-btn color="primary" @click="updateStep(5)">
              {{ t("done") }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="5">
            {{ t("step5.title") }}
          </v-stepper-step>
          <v-stepper-content step="5">
            <div class="mb-4">
              <i18n path="pages.getStarted.step5.description1">
                <template #url>
                  <a href="https://cabinet.costaction.com/faq" target="_blank">https://cabinet.costaction.com/faq</a>
                </template>
              </i18n>
              <br />
              <i18n path="pages.getStarted.step5.description2">
                <template #url>
                  <a href="https://help2.costaction.com" target="_blank">https://help.costaction.com</a>
                </template>
              </i18n>
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col lg="8" md="12" sm="12">
        <div>
          <v-expansion-panels>
            <v-expansion-panel
                    v-for="faqItem in getFaqItemsByIds(faqItems)"
            >
              <v-expansion-panel-header>
                <div>
                  <span class="d-block mb-1 text-body-1 text--darken-1">
                    <strong>{{ faqItem.question }}</strong>
                  </span>
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div class="text-body-1" v-html="replaceTemplate(faqItem.answer)"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CaPageHeader from "../../components/PageHeader";
import { mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: this.$t("titles.get_started")
    };
  },
  components: { CaPageHeader },
  data() {
    return {
      currentStep: null,
      activePrograms: {
        fd1: false,
        fd2: false
      },
      activatingProgram: {
        fd1: false,
        fd2: false
      },
      faqItems: []
    };
  },
  computed: {
    ...mapGetters("app", ["redirectorDomain", "cabinetDomain"]),
    hasActiveProgram() {
      return this.activePrograms.fd1 || this.activePrograms.fd2;
    }
  },
  mounted() {
    this.loadCurrentStep();
    this.loadFaq();
  },
  methods: {
    replaceTemplate(answer) {
      return answer
              .replaceAll("{fd1_domain}", this.redirectorDomain("fd1"))
              .replaceAll("{fd2_domain}", this.redirectorDomain("fd2"))
              .replaceAll("{ptr_cabinet_domain}", this.cabinetDomain)
    },
    getFaqItemsByIds() {
      return this.faqItems.filter(x => [17, 15, 2, 82, 94, 50, 52, 37, 95, 99, 100].includes(x.id));
    },
    loadFaq() {
      this.axios.get("/faq").then(response => {
        this.faqItems = response.data.items;
      });
    },
    loadCurrentStep() {
      this.axios.get("/get-started").then(response => {
        this.currentStep = response.data.step;
        this.activePrograms = response.data.active_programs;
      });
    },
    updateStep(step) {
      this.currentStep = step;
      this.axios.post("/get-started/step", { step });
    },
    activateProgram(app) {
      if (this.activePrograms[app]) {
        return;
      }

      this.activatingProgram[app] = true;

      this.$store
        .dispatch("auth/activateProgram", app)
        .then(result => {
          this.activatingProgram[app] = false;
          if (result) {
            this.activePrograms[app] = true;
          }
        })
        .catch(() => {
          this.activatingProgram[app] = false;
        });
    },
    t(key, params) {
      return this.$t("pages.getStarted." + key, params);
    }
  }
};
</script>
